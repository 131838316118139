<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>DDHH</h3>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item> LISTA DE EVENTOS </v-breadcrumbs-item>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            rounded
            class="mb-2"
            :to="{
              name: 'humansRights.createCase',
            }"
          >
            Nuevo Evento
          </v-btn>
        </template>
      </v-breadcrumbs>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-autocomplete
              :items="dataSource.data.Territorio"
              item-text="descripcion"
              item-value="territorio_id"
              single
              label="Territorio"
              v-model="filters.territory_id"
              prepend-icon="mdi-map-marker"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-dialog
              ref="dialog"
              v-model="dialog"
              :return-value.sync="filters.dates"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.dates"
                  label="Rango de fechas"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @click:clear="handleClearMinDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.dates"
                scrollable
                range
                locale="es-co"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(filters.dates)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-autocomplete
              prepend-icon="mdi-progress-check"
              placeholder="Estado"
              :items="states"
              item-text="name"
              v-model="filters.state_id"
              item-value="id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-autocomplete
              prepend-icon="mdi-event-blank-outline"
              placeholder="Tipo de evento"
              :items="eventsType"
              item-text="name"
              v-model="filters.event_type"
              item-value="id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-text-field
                v-model="search"
                label="Buscar por descripción del caso"
                single-line
                hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-btn
              rounded
              color="primary"
              dark
              class="mt-2"
              @click="onPaginate()"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-options': [5,10,15]
          }"
          :headers="headers"
          :items="events"
          :items-per-page="15"
          :search="search"
          item-key="id"
          :server-items-length="filtrado?.count"
          v-on:update:options="onPaginate"
        >
          <template v-slot:item.state="{ item }">
            <v-chip outlined :color="eventStateColor(item.state.name)">
              {{ item.state.name || "SIN ESTADO ASIGNADO" }}
            </v-chip>
          </template>
          <template v-slot:item.description="{ item }">
            <div class="text-ellipsis">
              {{ item.description }}
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editCase(item.id)" v-if="$can('handle_ddhh')">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-circle-edit-outline</v-icon>
                    Editar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { listEvents, listStates, listTypeEvents } from "@/services/humansRights";
import Swal from "sweetalert2";
import getEventStateColor from "../../utils/eventStateColors";
import { mapState } from "vuex";

export default {
  name: "ListOfCases.vue",
  data() {
    return {
      municipalities: [],
      territories: [],
      sidewalks: [],
      eventsType: [],
      events: [],
      search: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "TIPO DE EVENTO", value: "type.name", sortable: false },
        { text: "TERRITORIO", value: "territory.descripcion", sortable: false },
        { text: "RESUMEN", value: "description", sortable: false },
        { text: "FECHA", value: "date", sortable: true },
        {
          text: "NO. AFECTADOS",
          value: "affectedPeoplesCount",
          sortable: false,
        },
        { text: "COORDINADOR", value: "userAssigned", sortable: false },
        { text: "ESTADO", value: "state", sortable: false },
        { text: "ACCIÓN", value: "action", sortable: false },
      ],
      filters: {
        dates: [],
        event_type: "",
        territory_id: "",
        state_id: "",
        isFemale: 'false',
      },
      dialog: false,
      states: [],
      filtrado: {},
    };
  },
  methods: {
    handleClearMinDate(){
      this.filters.dates = [];
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
    editCase(id) {
      const selectedCase = this.events.find((x) => x.id === id);
      if (!selectedCase.isFemale) {
        this.$router.push({
          name: "humansRights.editCase",
          params: {
            id: id,
          },
        });
      } else {
        this.$router.push({
          name: "womenRights.case",
          params: {
            mode: "edit",
            id: id,
          },
        });
      }
    },
    getCurrentFilter() {
      return {
        ...(this.search && { search: this.search }),
      };
    },
    onPaginate: async function (
      pagination = { sortBy: [], sortDesc: [], itemsPerPage: 15, page: 1 }
    ) {
      const { sortBy, sortDesc, itemsPerPage, page } = pagination;
      this.filtrado = await this.list({
        page,
        itemsPerPage,
        isFemale: 'false',
        ...this.getCurrentFilter(),
        ...this.filters,
        ...(sortBy[0] && {[`${sortBy[0]}`]: sortDesc[0]})
      });
    },
    async list(options) {
      try {
        this.showLoader();
        const listEventsResponse = (await listEvents(options))?.data;
        this.events = listEventsResponse?.results
          .map((x) => {
            return {
              id: x.id,
              type: x.event_type,
              territory: x.territory,
              date: x.date,
              description: x.description,
              affectedPeoplesCount: x.affected.length,
              userAssigned: x.current_state.user
                ? `${x.current_state.user.first_name} ${x.current_state.user.last_name}`
                : "Sin Asignar",
              state: x.current_state.state,
              isFemale: x.is_female,
            };
          });
        this.hideLoader();
        return listEventsResponse;
      } catch (err) {
        this.hideLoader();
        console.error(err);
        Swal.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
  },
  async created() {
    const statesResponse = await listStates();
    const typeEvents = await listTypeEvents();
    this.states = statesResponse.data;
    this.eventsType = typeEvents.data.filter(x => !x.is_female);
  },
  computed: {
    ...mapState(["dataSource"]),
  },
};
</script>

<style scoped>
.text-ellipsis {
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
